import { createSlice } from '@reduxjs/toolkit'

const initialCasesState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	staffs: [],
	caseForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const casesSlice = createSlice({
	name: 'cases',
	initialState: initialCasesState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getCaseById
		caseFetched: (state, action) => {
			state.actionsLoading = false
			state.caseForEdit = action.payload.caseForEdit
			state.error = null
		},
		// findCases
		casesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// findCases
		staffsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.staffs = entities
		},
		// createCase
		caseCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateCase
		caseUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload._case.id) {
					return action.payload._case
				}
				return entity
			})
		},
		// deleteCase
		caseDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id
			)
		},
		// deleteCases
		casesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id)
			)
		},
		// casesUpdateState
		casesStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
		// caseUpdateState
		caseStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { status } = action.payload
			state.caseForEdit.status = status
		},
		// caseUpdateState
		caseAssignedToUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { id, assigned_to } = action.payload
			state.entities = state.entities.map((entity) => {
				if (entity.id === id) {
					entity.assigned_to = assigned_to
				}
				return entity
			})
		},
		// caseUpdateState
		mediaCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.caseForEdit.files.push(action.payload)
		},
	},
})
